<template>
  <ul v-if="error">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <li :class="msgClass" v-html="message" />
    <li v-for="(value, key) in inputErrors" :key="key" :class="msgClass">
      {{ value }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: null
    }
  },

  data: function() {
    return {
      message: '',
      inputErrors: [],
      msgClass: 'err-message'
    };
  },

  watch: {
    error: {
      immediate: true,
      handler: function(val) {
        if (!val) {
          return;
        }
        this.message = val.message;
        this.inputErrors = [];

        if (val?.extensions?.code === 'E0019B') {
          this.message = '';
          this.inputErrors = val.extensions.info;
        }

        if (val?.extensions?.code === 'E0001B' || val?.extensions?.code === 'E0004B') {
          this.msgClass = 'info-message';
        }
      }
    }
  }
};

</script>

<style scoped>
  .v-application .err-message {
    color: #EA1D76;
    list-style-type: none;
  }
  .v-application .info-message {
    color: rgba(41, 43, 49, 0.534);
    list-style-type: none;
  }
</style>
