import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)

/* pages */
const Home = () => import('./pages/Home' /* webpackChunkName: 'home' */);
const Login = () => import('./pages/Login' /* webpackChunkName: 'login' */);
const Admin = () => import('./pages/Admin' /* webpackChunkName: 'admin' */);
const LostPassword = () => import('./pages/LostPassword' /* webpackChunkName: 'lost-password' */);
const ResetPassword = () => import('./pages/ResetPassword' /* webpackChunkName: 'reset-password' */);
const Signup = () => import('./pages/Signup' /* webpackChunkName: 'signup' */);
const Confirm = () => import('./pages/Confirm'/* webpackChunkName: 'confirm'*/);
const Profile = () => import('./pages/Profile'/* webpackChunkName: 'profile'*/);
const EnableUser = () => import('./pages/EnableUser'/* webpackChunkName: 'enable-user'*/);
const resetUser = () => import('./pages/ResetUser'/* webpackChunkName: 'reset-user'*/);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Inicio'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/lost-password',
    name: 'lost-password',
    component: LostPassword,
    meta: {
      title: 'Recuperar contraseña'
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    meta: {
      title: 'Cambiar contraseña'
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
    meta: {
      title: 'Registrar Usuario'
    }
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: Confirm,
    meta: {
      title: 'Email confirmado'
    }
  },
  {
    path: '*',
    beforeEnter(to, from, next) {
      let redirect = {
        name: 'login',
        query: {
          appId: to.query.appId
        }
      };
      if(!to.query.appId) {
        redirect = {
          name: 'home'
        };
      }
      next(redirect)
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      title: 'Actualizar Usuario'
    }
  },
  {
    path: '/enable-user',
    name: 'enable-user',
    component: EnableUser,
    meta: {
      title: 'Habilitar Usuario'
    }
  },
  {
    path: '/reset-user',
    name: 'resetUser',
    component: resetUser,
    meta: {
      title: 'Restablecer Usuario'
    }
  },
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  let title = 'Cuenta DEMRE';
  if (to.meta && to.meta.title) {
    title = `Cuenta DEMRE - ${to.meta.title}`;
  }
  document.title = title;
  next();
})


export default router;
