import Vue from 'vue';
import App from './App.vue';
import archivos from '@demre/client-common/src/plugins/archivos';
import router from './router';
const { input, validator } = require('@demre/input');

import DError from './components/Error';

Vue.component("DError", DError);

Vue.use(archivos);
Vue.config.productionTip = false;

const path = window.location.pathname.substring(1);

function validate() {
  if(!path) {
    throw null;
  }
  let schema = {
    appId: input().read('appId').type('string'),
  };

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  switch(path) {
    case 'profile':
      schema = {
        appId: input().read('appId').type('string'),
        username: input().read('username').type('string').minLength(1).maxLength(50),
      };
      break;
    case 'enable-user':
      schema = {
        appId: input().read('appId').type('string'),
        username: input().read('username').type('string').minLength(1).maxLength(50),
        hmac: input().read('hmac').type('string').minLength(1).maxLength(50),
      };
      break;
  }
  const result = validator(schema, params);
  if(result.hasErrors()) {
    throw {
      extensions: {
        code: 'E0019B',
        info: Object.values(result.errors)
      }
    };
  }
  return result.values || {};
}

async function init() {
  window.DATA = {};
  try {
    const { appId, username, hmac } = validate();
    const queryParams = {
      appId,
      path,
      ...(path === 'enable-user' && { username, hmac }),
      ...(path === 'profile' && { username })
    };
    const response = await fetch(`${process.env.VUE_APP_SERVER_HOST}/init?${new URLSearchParams(queryParams)}`);
    const data = await response.json();
    if(response.status != 200) {
      window.ERROR = data.errors[0];
      return;
    }
    window.DATA = data;
  } catch(error) {
    window.ERROR = error;
  }
}

init().then(async () => {
  const { default: vuetify } = await import('./plugins/vuetify');
  new Vue({
    vuetify,
    router,
    render: h => h(App)
  }).$mount('#app')
});


document.addEventListener('keydown', e => {
  const goToAdmin = e.ctrlKey && (e.keyCode === 65 || e.keyCode === 95); // ctrl + a
  if (goToAdmin) {
    window.location.pathname = '/admin';
    e.preventDefault();
    e.stopPropagation();
  }
}, true);
